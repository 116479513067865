import Router from "preact-router";
import "tailwindcss/dist/tailwind.min.css";
import "./style.css";
import "./database";
import Home from "./pages/home";
import Scrapboard from "./pages/scrapboard";

// https://dcct0.csb.app/c8293910-1084-4e8f-b747-340aa4ccc108
// http://0.0.0.0:8080/b7b26c09-f800-4df8-bd04-73092aac4c7b

const App = () => {

  return (
    <div class="md:px-10 mx-auto text-gray-700">
      <Router>
        <Home path="/" />
        <Scrapboard path="/:scrapboard" />
      </Router>
    </div>
  );
};

export default App;
