import { useEditor, EditorContent } from "@tiptap/react";
import { useEffect, useMemo, useRef } from "preact/hooks";
import { db } from "../../database";

import StarterKit from "@tiptap/starter-kit";
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import * as Y from 'yjs'
import { WebrtcProvider } from 'y-webrtc'

import TipTapMenu from "./tiptap-menu";

const TipTapEditor = ({ scraps, scrapboardKey, scrapKey, scrap, name, color }) => {
  const node = useRef();
  const timeoutRef = useRef(null);

  const ydoc = useMemo(() => {
    const doc = new Y.Doc();

    doc.on('update', () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        const currentDocState = Y.encodeStateAsUpdate(ydoc);
        // const currentDocStateString = new TextDecoder().decode(currentDocState);

        const scrapboardRef = db.ref(`scrapboards/${scrapboardKey}`);
        scrapboardRef.update({ [`scraps/${scrapKey}/ydoc`]: currentDocState });
      }, 2000);

    })

    return doc;
  }, [scrapboardKey, scrapKey]);

  useEffect(() => {
    if (!scrap || !scrap.ydoc) {
      return;
    }
    try {
      Y.applyUpdate(ydoc, new Uint8Array(scrap.ydoc));
    } catch (error) {
      console.error(error);
    }
    
  }, [scrap, ydoc]);

  const realtimeProvider = useMemo(() => {
    console.log('create WebrtcProvider', scrapKey);
    return new WebrtcProvider(scrapKey, ydoc);
  }, [scrapKey, ydoc]);


  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        history: false,
      }),
      TaskList,
      TaskItem,
      Collaboration.configure({
        document: ydoc,
      }),
      CollaborationCursor.configure({
        provider: realtimeProvider,
        user: {
          name,
          color: `#${color}`,
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: "text-gray-700 focus:outline-none"
      }
    }
  });

  // update user
  useEffect(() => {
    console.log('UPDATE USER');
    if (!editor) {
      return;
    }
    editor.chain().user(
      {
        name,
        color: `#${color}`,
      },
    ).run()
  }, [editor, name, color])

  return (
    <div ref={node} class="bg-gray-100 rounded-md">
      <pre class="hidden rounded-md bg-gray-100 p-2">
        {JSON.stringify(scrap.order, null, 2)}
      </pre>
      <TipTapMenu editor={editor} scraps={scraps} scrap={scrap} scrapboardKey={scrapboardKey} />
      <EditorContent editor={editor} class="bg-white border-l-2 border-r-2 border-b-2 rounded-b-md p-3 prose prose-sm overflow-y-auto" />
    </div>
  );
};

export default TipTapEditor;
