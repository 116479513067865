import { db } from "../../database";

const TipTapMenu = ({ editor, scrapboardKey, scraps, scrap }) => {
  if (!editor) {
    return null;
  }

  return (
    <div class="bg-white p-2 border-2 rounded-t-md text-gray-500 text-sm flex flex-wrap items-center">

      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("bold") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-bold" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("italic") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-italic" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("strike") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-strikethrough" />
      </button>

      <div class="bg-gray-200 ml-1 mr-1" style={{ width: '1px', height: '1rem' }} />

      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("heading", { level: 1 }) ? "bg-gray-700 text-white" : ""}`}
      >
        H1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("heading", { level: 2 }) ? "bg-gray-700 text-white" : ""}`}
      >
        H2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("heading", { level: 3 }) ? "bg-gray-700 text-white" : ""}`}
      >
        H3
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("paragraph") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-paragraph" />
      </button>

      <div class="bg-gray-200 ml-1 mr-1" style={{ width: '1px', height: '1rem' }} />

      <button
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("taskList") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-tasks" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("bulletList") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-list-ul" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("orderedList") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-list-ol" />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("codeBlock") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-code" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("blockquote") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-quote-right" />
      </button>

      <div class="bg-gray-200 ml-1 mr-1" style={{ width: '1px', height: '1rem' }} />

      <div className="ml-auto">
        <button
          class="px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none"
          onClick={() => {

            const updates = {
              [`scraps/${scrap.key}`]: null,
            }

            const newScraps = [
              ...scraps,
            ];
            newScraps.splice(scrap.order, 1);
            newScraps.forEach((newScrap, index) => {
              updates[`scraps/${newScrap.key}/order`] = index;
            })

            const scrapboardRef = db.ref(`scrapboards/${scrapboardKey}`);
            scrapboardRef.update(updates);
          }}
        >
          <i class="far fa-trash-alt" />
        </button>

        {
          !!scrap.order
          && scrap.order > 0
          && (
          <button
            class="px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none"
            onClick={() => {
              const currentScrap = scraps[scrap.order];
              const prevScrap = scraps[scrap.order - 1];
              
              const updates = {
                [`scraps/${currentScrap.key}/order`]: currentScrap.order - 1,
                [`scraps/${prevScrap.key}/order`]: prevScrap.order + 1,
              }

              const scrapboardRef = db.ref(`scrapboards/${scrapboardKey}`);
              scrapboardRef.update(updates);
            }}
          >
            <i class="far fa-long-arrow-left" />
          </button>
          )
        }
        {
          (
            !scrap.order
            || scrap.order < scraps.length - 1
          )
          && (
            <button
              class="px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none"
              onClick={() => {
                const currentScrap = scraps[scrap.order];
                const nextScrap = scraps[scrap.order + 1];
                
                const updates = {
                  [`scraps/${currentScrap.key}/order`]: currentScrap.order + 1,
                  [`scraps/${nextScrap.key}/order`]: nextScrap.order - 1,
                }
  
                const scrapboardRef = db.ref(`scrapboards/${scrapboardKey}`);
                scrapboardRef.update(updates);
              }}
            >
              <i class="far fa-long-arrow-right" />
            </button>
          )
        }
      </div>

    </div>
  );
};

export default TipTapMenu;

{
  /*
  <button
        onClick={() => editor.chain().focus().undo().run()}
        class="px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none"
      >
        <i class="far fa-undo" />
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        class="px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none"
      >
        <i class="far fa-redo" />
      </button>

      <div class="bg-gray-200 ml-1 mr-1" style={{ width: '1px', height: '1rem' }} />
    <div class="bg-gray-200 ml-1 mr-1" style={{ width: '1px', height: '1rem' }} />

      <button
        class="px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none"
      >
        <i class="far fa-long-arrow-left" />
      </button>
      <button class="px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none">
        <i class="far fa-long-arrow-right" />
      </button>
    <button
      onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
      class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("heading", { level: 4 }) ? "bg-gray-700 text-white" : ""}`}
    >
      h4
    </button>
    <button
      onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
      class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("heading", { level: 5 }) ? "bg-gray-700 text-white" : ""}`}
    >
      h5
    </button>
    <button
      onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
      class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("heading", { level: 6 }) ? "bg-gray-700 text-white" : ""}`}
    >
      h6
    </button>
    <button
      onClick={() => editor.chain().focus().unsetAllMarks().run()}
      class="hidden"
    >
      clear marks
    </button>
    <button
      onClick={() => editor.chain().focus().clearNodes().run()}
      class="hidden"
    >
      clear nodes
    </button>
    <button
      onClick={() => editor.chain().focus().setHorizontalRule().run()}
      class="hidden"
    >
      horizontal rule
    </button>
    <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("code") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-code" />
      </button>
  */
  }