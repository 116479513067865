import { useEffect, useState } from "preact/hooks";
import { Link } from "preact-router/match";
import * as uuid from 'uuid';
import { db } from "../../database";
import TipTapEditor from "./tiptap-editor";
import pokemon from '../../../data/pokemon';
const colors = ["ffadad","ffd6a5","fdffb6","caffbf","9bf6ff","a0c4ff","bdb2ff","ffc6ff"];

const Scrapboard = (props) => {
  const { matches } = props;
  const scrapboardKey = matches.scrapboard || null;
  const [scrapboard, setScrapboard] = useState();
  const scrapboardRef = db.ref(`scrapboards/${scrapboardKey}`);
  const [gridSize, setGridSize] = useState(2);
  const [name, setName] = useState(pokemon[Math.floor(Math.random() * pokemon.length)].name);
  const [color, setColor] = useState(colors[Math.floor(Math.random() * colors.length)]);

  useEffect(() => {
    scrapboardRef.on("value", (snapshot) => {
      const data = snapshot.val();
      setScrapboard(data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // https://stackoverflow.com/a/60327893

  // Check scrapboard key
  if (!scrapboardKey || !uuid.validate(scrapboardKey)) {
    return (
      <div class="h-screen">
        <div class="flex justify-center h-1/2 items-center">
          <div>
            Oops, something went wrong..
            <Link
              class="block mt-5 rounded-md border-2 border-gray-600 px-4 py-2 hover:bg-yellow-100 focus:outline-none"
              href="/"
            >
              Go back to the homepage
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const scraps = scrapboard && scrapboard.scraps
    ? Object.keys(scrapboard.scraps).map((scrapKey) => ({ ...scrapboard.scraps[scrapKey], key: scrapKey }))
    : [];

  const compareScrapOrder = ( a, b ) => {
    if ( a.order < b.order ){
      return -1;
    }
    if ( a.order > b.order ){
      return 1;
    }
    return 0;
  }
  scraps.sort( compareScrapOrder );

  return (
    <div class="p-5">
      <pre class="hidden rounded-md bg-gray-100 p-2">
        {JSON.stringify(props, null, 2)}
      </pre>
      <pre class="hidden rounded-md bg-gray-100 p-2">
        {JSON.stringify(scrapboard, null, 2)}
      </pre>
      <div class="mt-4 rounded-md bg-gray-100 border-2 p-2 md:flex items-center">
        <div class="flex items-center">
          <div class="font-bold mr-2 text-xs">Gridsize:</div>
          <input
            class="w-20 appearance-none border rounded py-1 px-2 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Gridsize"
            value={gridSize}
            onChange={(input) => {
              setGridSize(input.target.value)
            }}
          />
        </div>
        <div class="ml-5 flex items-center">
          <div class="font-bold mr-2 text-xs">Name:</div>
          <input
            class="w-40 appearance-none border rounded py-1 px-2 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Name"
            value={name}
            onChange={(input) => {
              setName(input.target.value)
            }}
          />
        </div>
        <button
          class="ml-auto rounded-md bg-gray-700 text-white px-2 py-1 hover:bg-gray-800 focus:outline-none"
          onClick={() => {
            // return;
            scrapboardRef.child("scraps").child(uuid.v4()).set({
              created: new Date().valueOf(),
              order: scraps.length
            });
          }}
        >
          Add Scrap
        </button>
      </div>
      <div class={`mt-4 grid lg:grid-cols-${gridSize} gap-4`}>
        {
          !!scraps &&
          scraps.map((scrap) => {
            return (
              <TipTapEditor
                key={scrap.key}
                scraps={scraps}
                scrapboardKey={scrapboardKey}
                scrapboardRef={scrapboardRef}
                scrapKey={scrap.key}
                scrap={scrap}
                name={name}
                color={color}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default Scrapboard;
