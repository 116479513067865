import { Link } from "preact-router/match";
import * as uuid from 'uuid';

const Home = () => {
  return (
    <div class="h-screen">
      <div class="flex justify-center h-1/2 items-center">
        <div>
          <h1 class="text-center font-bold">scrap.app</h1>
          <Link
            class="block mt-5 rounded-md border-2 border-gray-600 px-4 py-2 hover:bg-yellow-100 focus:outline-none"
            href={`/${uuid.v4()}`}
          >
            Create new Scrapboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
