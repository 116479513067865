import firebase from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyCZ3lR5J1PirWh1NXnzkmJTaDAbvKghZ6E",
  authDomain: "scrap-wtf.firebaseapp.com",
  databaseURL:
    "https://scrap-wtf-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "scrap-wtf",
  storageBucket: "scrap-wtf.appspot.com",
  messagingSenderId: "345053306081",
  appId: "1:345053306081:web:68b1cf81977a51c463aba0"
});

export const db = firebase.database();
